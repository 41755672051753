@import 'styles/mixins';

$arrow-offset: 10px;

.card:hover {
  @include breakpoint-sm {
    border-color: #fff !important;
  }
}

.arrow-left {
  right: $arrow-offset;
}

.arrow-right {
  left: $arrow-offset;
}

.gradient-left {
  left: $arrow-offset;
}

.gradient-right {
  right: $arrow-offset;
}
