@import 'styles/mixins';

.itemsPadding {
  padding: 2rem 0 !important;
}

.dropContent {
  flex: 1;
  border-radius: 20px;
  background: rgb(255 252 249 / 0.08);
  box-shadow: 0 1px 24px 0 rgb(0 0 0 / 0.06);

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.sliderHomeArrow {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.sliderArrow {
  z-index: 5;
  position: absolute;
  top: calc(50% - 27.5px);
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: rgb(27 28 29 / 0.69) !important;
  border: 3px solid rgb(255 255 255 / 0.24);
  filter: drop-shadow(0 4px 32px rgb(0 0 0 / 0.25));
  width: 55px !important;
  height: 55px !important;
  transition: border-color 200ms;

  &:hover {
    border-color: rgb(255 255 255 / 0.12);
  }
}

.auctionListingBg {
  background-color: rgb(255 252 249 / 0.08);
}

.mobileTabItem {
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 2;
}

.modal {
  background-color: #242526 !important;
}

input.input {
  background-color: #242526 !important;
  color: #fffcf9;

  &::placeholder {
    color: #fffcf9;
    font-size: 44px;
    line-height: 56px;
    opacity: 0.4;
  }

  &:focus {
    color: #fffcf9;
  }
}

.box {
  border-radius: 16px;
  border: 1px solid rgb(249 247 242 / 0.2);
  background: rgb(255 252 249 / 0);
  box-shadow: 0 1px 24px 0 rgb(0 0 0 / 0.06);
}

.lightBorderColor {
  border-color: rgb(255 252 249 / 0.2) !important;
}

.allBidsContent {
  width: 100%;
  z-index: 1018;
}

.buyersPremiumButtonDark:hover {
  color: var(--color-light) !important;
}

.buyersPremiumButtonLight:hover {
  color: var(--color-primary) !important;
}

.signUpBlur {
  width: 100%;
  height: 55%;
  filter: blur(8px);
  position: absolute;
  opacity: 0.4;
}

.logInText {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.signUpText {
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
}
